import { useState } from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@nike/eds';

export interface DonutInputData {
  label: string;
  value: number;
}

export interface DonutParams {
  donutData: DonutInputData[];
  valueLabel: string;
  baseColor: string;
}

type DonutData = DonutInputData & { color: string };

const addColor = (donutData: DonutInputData[], baseColor: string): DonutData[] =>
  donutData.map((d, i) => ({
    ...d,
    color: `var(--eds-color-${baseColor}-${100 - ((i * 10) % 100)}`,
  }));

export function Donut({ donutData, valueLabel, baseColor }: DonutParams) {
  const [active, setActive] = useState<DonutData | null>(null);
  const width = 250;
  const half = width / 2;

  const valTotal = donutData.reduce((total, current) => total + current.value, 0);

  const finalData = addColor(donutData, baseColor);

  return (
    <svg width={width} height={width} style={{ display: 'block', margin: 'auto' }}>
      <Group top={half} left={half}>
        <Pie
          data={finalData}
          pieValue={data => data.value}
          outerRadius={half}
          innerRadius={({ data }) => {
            const size = active && active.label === data.label ? 24 : 18;
            return half - size;
          }}
          padAngle={0.01}
        >
          {pie =>
            pie.arcs.map(arc => (
              <g
                key={arc.data.label}
                onMouseEnter={() => setActive(arc.data)}
                onMouseLeave={() => setActive(null)}
              >
                <path d={pie.path(arc) || undefined} fill={arc.data.color} />
              </g>
            ))
          }
        </Pie>

        {active ? (
          <text
            textAnchor="middle"
            fill={active.color}
            fontSize={20}
            dy={20}
            className="eds-type--title-5"
          >
            {`${active.label}: ${active.value} `}
          </text>
        ) : (
          <text
            textAnchor="middle"
            fontSize={20}
            dy={20}
            fill="var(--eds-color-text-secondary)"
            className="eds-type--title-5"
          >
            {valTotal} {valueLabel}
          </text>
        )}
      </Group>
    </svg>
  );
}
