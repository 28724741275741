import { useState } from 'react';
import { Text, Card, Table, TableCell, ToggleButton, ToggleButtonGroup } from '@nike/eds';
import { match, P } from 'ts-pattern';
import {
  DefinitionSolveQueryResponse,
  DefinitionSolveDynamoRow,
} from '@nike.innovation/composure-sdk';
import { Donut, DonutInputData } from '../../../../shared/utils/donut-chart';

type DateFilter = 'all' | 31 | 7 | 1;
type ScalarCard = {
  label: string;
  value: string;
};

const filterSolves = (
  solves: DefinitionSolveQueryResponse,
  dateFilter: DateFilter
): DefinitionSolveDynamoRow[] => {
  const current = new Date();
  const filterFunction = match(dateFilter)
    .with(
      P.number,
      f => (solve: DefinitionSolveDynamoRow) =>
        new Date(solve.sk) > new Date(new Date().setDate(current.getDate() - f))
    )
    .with('all', f => (solve: DefinitionSolveDynamoRow) => true)
    .exhaustive();
  return solves.result.filter(filterFunction);
};

const getCounts = (filteredSolves: DefinitionSolveDynamoRow[]) =>
  filteredSolves.reduce(
    (acc, s) => {
      acc.userCounts[s.user] = acc.userCounts[s.user] ? acc.userCounts[s.user] + 1 : 1;
      acc.sourceCounts[s.source] = acc.sourceCounts[s.source] ? acc.sourceCounts[s.source] + 1 : 1;
      return acc;
    },
    { userCounts: {} as { [Name: string]: number }, sourceCounts: {} as { [Name: string]: number } }
  );

export interface DefinitionUsageTabParams {
  solves: DefinitionSolveQueryResponse;
}

/**
 * Displays basic usage statistics for a given definition.
 * @param { solves} - Solves for the definition from querying AWS
 * @returns
 */
export function DefinitionUsageTab({ solves }: DefinitionUsageTabParams) {
  const [dateFilter, setDateFilter] = useState<DateFilter>('all');
  const filteredSolves = filterSolves(solves, dateFilter);
  const totalRuns = filteredSolves.length;
  const { userCounts, sourceCounts } = getCounts(filteredSolves);
  const avgExecutionTime =
    filteredSolves.length > 0
      ? filteredSolves.map(r => r.executionTime).reduce((total, cur) => total + cur / 1000, 0) /
        solves.count
      : undefined;
  const scalarCards: ScalarCard[] = [
    { label: 'Total Runs', value: String(totalRuns) },
    { label: 'Average Execution Time (s)', value: String(avgExecutionTime || 'N/A') },
  ];
  // TODO: Needs actual values here
  const runSources: DonutInputData[] = Object.keys(sourceCounts).map(k => ({
    label: k,
    value: sourceCounts[k],
  }));

  return (
    <>
      <Text font="title-3" className="eds-spacing--mb-16">
        Usage
      </Text>

      <ToggleButtonGroup size="medium" style={{ marginBottom: '0.5em' }}>
        <ToggleButton
          onClick={() => setDateFilter('all')}
          active={dateFilter === 'all'}
          title="All Results"
        >
          See All Results
        </ToggleButton>
        <ToggleButton
          onClick={() => setDateFilter(31)}
          active={dateFilter === 31}
          title="See Previous Month"
        >
          See Previous Month
        </ToggleButton>
        <ToggleButton
          onClick={() => setDateFilter(7)}
          active={dateFilter === 7}
          title="See Previous Week"
        >
          See Previous Week
        </ToggleButton>
        <ToggleButton
          onClick={() => setDateFilter(1)}
          active={dateFilter === 1}
          title="See Previous Day"
        >
          See Previous Day
        </ToggleButton>
      </ToggleButtonGroup>
      <Text style={{ marginBottom: '1em' }} color="text-secondary" font="legal-1">
        View Results from the last day, week, month, or all results
      </Text>

      <div className="eds-grid eds-grid--m-cols-2">
        {scalarCards.map(scalar => (
          <Card key={scalar.label} className="eds-grid--m-col-1 eds-elevation--2">
            <Text font="title-5">{scalar.label}</Text>
            <hr />
            <Text font="body-1">{scalar.value}</Text>
          </Card>
        ))}

        <Card className="eds-grid--m-col-1 eds-elevation--2">
          <Text font="title-5">Runs by User</Text>
          <hr />
          {filteredSolves.length > 0 ? (
            <Table style={{ width: '100%', marginTop: '2em' }}>
              <tbody>
                {Object.keys(userCounts).map(k => (
                  <tr key={k}>
                    <TableCell className="user-run-table-cell">{k}</TableCell>
                    <TableCell className="user-run-table-cell" style={{ textAlign: 'right' }}>
                      {userCounts[k]}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Text font="body-1">N/A</Text>
          )}
        </Card>
        <Card className="eds-grid--m-col-1 eds-elevation--2">
          <Text font="title-5">Run Sources</Text>
          <hr />
          {filteredSolves.length > 0 ? (
            <div style={{ marginTop: '2em' }}>
              <Donut
                donutData={runSources}
                valueLabel={totalRuns > 1 ? 'Runs' : 'Run'}
                baseColor="blue"
              />
            </div>
          ) : (
            <Text font="body-1">N/A</Text>
          )}
        </Card>
      </div>
    </>
  );
}
