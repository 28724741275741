import { GrasshopperDefinition } from '@nike.innovation/composure-sdk';
import { Box, Card, Text, Spinner } from '@nike/eds';

import { useOktaAuth } from '@okta/okta-react';
import { P, match } from 'ts-pattern';
import { PaginationShowMore } from '../../../../shared/pagination-show-more';
import { DefinitionVersionTable } from '../../../../shared/version-table/definition-version-table';
import { Tabtable, tabsntableTuple } from '../../../../shared/tabtable/tabtable';
import RunForm from '../run-form/run-form';
import { DefinitionSolveTable } from './definition-solve-table';
import { useDefinitionSolvesQuery } from '../../../../shared/hooks/use-definition-solves-query';
import { getUserRole } from '../../../../shared/utils/entitlement-utils';
// import { DefinitionUsageTab } from './definition-usage-tab';

import './details-card.css';
import { DefinitionUsageTab } from './definition-usage-tab';

interface DetailsCardProps {
  definition: GrasshopperDefinition;
}

export function DetailsCard({ definition }: DetailsCardProps) {
  const { oktaAuth, authState } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const role = getUserRole(authState, definition.entitlements);

  const { solves, solvesLoading, solvesFetchErr, resultMutate, setSolvesPage, solvesPage } =
    useDefinitionSolvesQuery(definition.id, definition.versionId, 10, accessToken);

  const runTab: tabsntableTuple = [
    { value: 'run', label: 'Run' },
    definition.io && (
      <RunForm
        definitionId={definition.id}
        io={definition.io}
        versionId={definition.versionId}
        resultMutate={resultMutate}
        authToken={accessToken}
        role={role}
      />
    ),
  ];

  const resultsTab: tabsntableTuple = [
    { label: 'Results', value: 'Results' },
    <>
      {match({ solves, solvesLoading, solvesFetchErr })
        .with({ solves: { count: 0 }, solvesLoading: false, solvesFetchErr: undefined }, () => (
          <Text font="body-1" style={{ marginTop: '1em' }}>
            No runs for definition.
          </Text>
        ))
        .with({ solvesLoading: false, solvesFetchErr: undefined }, () => (
          <>
            <DefinitionSolveTable
              rows={solves.result}
              defId={definition.id}
              verId={definition.versionId}
            />
            {solves.last ? (
              <PaginationShowMore
                page={solvesPage}
                setPage={setSolvesPage}
                solvesLoading={solvesLoading}
              />
            ) : (
              <Box />
            )}
          </>
        ))
        .with({ solvesLoading: false, solvesFetchErr: { message: P.string } }, () => (
          <Text font="body-1" style={{ marginTop: '1em' }}>
            {solvesFetchErr.message}
          </Text>
        ))
        .otherwise(() => (
          <Spinner />
        ))}
    </>,
  ];

  const versionTab: tabsntableTuple = [
    { value: 'version', label: 'Versions' },
    <Card className="eds-elevation--2">
      <Text font="title-3" className="eds-spacing--mb-16">
        Versions
      </Text>

      <DefinitionVersionTable
        assetId={definition.id}
        currentVersionId={definition.versionId}
        downloadEnabled={role !== 'Viewer'}
      />
    </Card>,
  ];

  const usageTab: tabsntableTuple = [
    { label: 'Usage', value: 'Usage' },
    <>
      {match({ solves, solvesLoading, solvesFetchErr })
        .with({ solves: { count: 0 }, solvesLoading: false, solvesFetchErr: undefined }, () => (
          <Text font="body-1" style={{ marginTop: '1em' }}>
            No runs for definition.
          </Text>
        ))
        .with({ solvesLoading: false, solvesFetchErr: undefined }, () => (
          <DefinitionUsageTab solves={solves} />
        ))
        .with({ solvesLoading: false, solvesFetchErr: { message: P.string } }, () => (
          <Text font="body-1" style={{ marginTop: '1em' }}>
            {solvesFetchErr.message}
          </Text>
        ))
        .otherwise(() => (
          <Spinner />
        ))}
    </>,
  ];

  const tabsntables = [runTab, resultsTab, versionTab, usageTab];

  // TODO: add err handling here
  return match(solvesLoading)
    .with(false, () => <Tabtable tabsntables={tabsntables} />)
    .otherwise(() => (
      <Card className="eds-elevation--2">
        <Spinner size="large" style={{ margin: 'auto' }} />
      </Card>
    ));
}

export default DetailsCard;
